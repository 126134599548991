<template>
  <div class="unauthorized-wrapper">
    <h2 class="unauthorized_header">You are not authorized to view this page</h2>
  </div>
</template>

<script>
  export default {
    name: 'unauthorized'
  }
</script>

<style lang="scss" scoped>
@import "../assets/css/styles.scss";

.unauthorized-wrapper {
    width: 94%;
    position: relative;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;

  .unauthorized_header {
      margin: $mds-space-2-x 0;
      @include mds-level-2-heading($bold: false);
  }
}
</style>